<template>
  <div class="market">
    <div class="market-l">
      <div class="l-title">在线商城</div>
      <div class="tips">商城数据统计</div>
      <div class="stat">
        <div class="stat-items">
          <p><span>5399</span><span>人</span></p>
          <span>用户总数</span>
        </div>
        <div class="stat-items">
          <p><span>5302.90</span><span>万元</span></p>
          <span>总交易额</span>
        </div>
        <div class="stat-items">
          <p><span>13329</span><span>条</span></p>
          <span>累积更新数据</span>
        </div>
        <div class="stat-items">
          <p><span>89</span><span>条</span></p>
          <span>今日更新数据</span>
        </div>
        <div class="stat-items">
          <p><span>23.6</span><span>万元</span></p>
          <span>本月新增交易额</span>
        </div>
        <div class="stat-items">
          <p><span>38</span><span>人</span></p>
          <span>本月新增用户数</span>
        </div>
      </div>
      <div class="tips">成交总金额</div>

      <div class="knockdown">
        <div class="knockdown-items">
          <p><span>7353.24</span><span>吨</span></p>
          <span>成交总量</span>
        </div>
        <div class="knockdown-items">
          <p><span>5302.90</span><span>万元</span></p>
          <span>成交总金额</span>
        </div>
        <div class="knockdown-items">
          <p><span>3342.41</span><span>吨</span></p>
          <span>大米成交量</span>
        </div>
        <div class="knockdown-items">
          <p><span>4010.83</span><span>吨</span></p>
          <span>大米淀粉成交量</span>
        </div>
        <div class="knockdown-items">
          <p><span>57.70</span><span>万元</span></p>
          <span>大米成交额</span>
        </div>
        <div class="knockdown-items">
          <p><span>27.21</span><span>万元</span></p>
          <span>大米淀粉成交额</span>
        </div>
      </div>
    </div>
    <div class="market-c">
      <div class="tips">大米交易中心</div>
      <div class="c-table">
        <div class="table-title">
          <p>序号</p>
          <p>图片</p>
          <p>商品名</p>
          <p>价格</p>
          <p>销量</p>
          <p>库存</p>
        </div>
        <div class="content-out">
          <div class="table-content" v-for="(t, i) in shopList" :key="i">
            <div>{{ i + 1 }}</div>
            <div><img :src="t.img" alt="" /></div>
            <div>{{ t.title }}</div>
            <div class="price">{{ t.price }}元</div>
            <div>{{ t.xiaoliang }}</div>
            <div>{{ t.ku }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="market-r">
      <MyPhone :isBoxShowNum="isBoxShowNum" @lookApp="lookApp"></MyPhone>
    </div>
    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyPhone: () => import("@/components/MyPhone.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      statList: [],
      isBoxShowNum: 14,
      shopList: [
        {
          index: "001",
          img: require("@/assets/image/shop/xianmi5kg.jpg"),
          title: "籼米(5kg)",
          price: 40,
          xiaoliang: 123,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/jingmi900g.jpg"),
          title: "粳米(900g)",
          price: 10,
          xiaoliang: 52,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/jingmi2_5kg.jpg"),
          title: "粳米(2.5kg)",
          price: 25,
          xiaoliang: 641,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/xianmi900g.jpg"),
          title: "籼米(900g)",
          price: 8,
          xiaoliang: 235,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/xianmi5kg_2.jpg"),
          title: "籼米(5kg)",
          price: 40,
          xiaoliang: 864,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/xianmi2_5kg.jpg"),
          title: "籼米(2.5kg)",
          price: 20,
          xiaoliang: 452,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/jingmi2_5kg_2.jpg"),
          title: "粳米(2.5kg)",
          price: 25,
          xiaoliang: 734,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/jingmi5kg.jpg"),
          title: "粳米(5kg)",
          price: 50,
          xiaoliang: 532,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/xianmi1kg.jpg"),
          title: "籼米(1kg)",
          price: 8,
          xiaoliang: 12,
          ku: 9999,
        },
        {
          index: "001",
          img: require("@/assets/image/shop/jingmi1kg.jpg"),
          title: "粳米(1kg)",
          price: 10,
          xiaoliang: 32,
          ku: 9999,
        },

        {
          index: "001",
          img: require("@/assets/image/shop/icon1.png"),
          title: "紫香米",
          price: 25,
          xiaoliang: 127,
          ku: 9999,
        },
        {
          index: "002",
          img: require("@/assets/image/shop/icon2.png"),
          title: "小红稻米",
          price: 25,
          xiaoliang: 865,
          ku: 9999,
        },
        {
          index: "003",
          img: require("@/assets/image/shop/icon3.png"),
          title: "老黑豆",
          price: 15.9,
          xiaoliang: 0,
          ku: 9999,
        },
        {
          index: "004",
          img: require("@/assets/image/shop/icon4.png"),
          title: "黑芝麻",
          price: 98,
          xiaoliang: 658,
          ku: 9999,
        },
        {
          index: "005",
          img: require("@/assets/image/shop/icon5.png"),
          title: "薏仁米",
          price: 19.9,
          xiaoliang: 425,
          ku: 9999,
        },
        {
          index: "006",
          img: require("@/assets/image/shop/icon6.png"),
          title: "玉米碴",
          price: 19.9,
          xiaoliang: 356,
          ku: 9999,
        },
        {
          index: "007",
          img: require("@/assets/image/shop/icon7.png"),
          title: "黄小米",
          price: 88,
          xiaoliang: 147,
          ku: 9999,
        },
        {
          index: "008",
          img: require("@/assets/image/shop/icon8.png"),
          title: "芡实米",
          price: 68,
          xiaoliang: 369,
          ku: 9999,
        },
        {
          index: "009",
          img: require("@/assets/image/shop/icon9.png"),
          title: "藜麦米",
          price: 50,
          xiaoliang: 189,
          ku: 9999,
        },
      ],
    };
  },
  methods: {
    lookApp() {
      this.isBoxShowNum = 13;
    },
  },
};
</script>

<style lang="less" scoped>
.market {
  width: 100%;
  height: 100vh;
  background: #071a2c;
  padding: 76px 0 0;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .tips {
    width: 140px;
    height: 33px;
    line-height: 33px;
    background: #3e90e5;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 28px;
  }
  .market-l {
    width: 481px;
    height: 100%;
    background: #142d4c;
    padding: 30px 58px;
    .l-title {
      font-size: 22px;
      font-weight: 400;
      color: #fff;
      position: relative;
      padding: 0 0 0 11px;
      margin-bottom: 27px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 22px;
        background: #3e90e5;
        border-radius: 2px;
      }
    }
    .tips {
    }
    .stat {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 37px;
      .stat-items {
        width: 174px;
        height: 85px;
        background: #1a385d;
        border: 1px solid #214a7a;
        border-radius: 10px;
        margin: 0 16px 16px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        P {
          span {
            &:first-of-type {
              font-size: 24px;
              font-family: DIN;
              margin-right: 4px;
            }
          }
        }
        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
    .knockdown {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .knockdown-items {
        width: 174px;
        height: 85px;
        border-radius: 10px;
        background: #1a385d;
        margin: 0 16px 16px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1) {
          background: #7b41f2;
        }
        &:nth-of-type(2) {
          background: #0180f5;
        }
        &:nth-of-type(3) {
          background: #fa6656;
        }
        &:nth-of-type(4) {
          background: #2db06e;
        }
        &:nth-of-type(5) {
          background: #ffa800;
        }
        &:nth-of-type(6) {
          background: #0297c8;
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        P {
          span {
            &:first-of-type {
              font-size: 24px;
              font-family: DIN;
              margin-right: 4px;
            }
          }
        }
        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  .market-c {
    height: 100%;
    // flex: 1;
    width: 956px;
    background: #0e2138;
    margin: 0 16px;
    padding: 30px 58px;
    .c-table {
      width: 100%;
      height: calc(100% - 70px);
      .table-title {
        width: 100%;
        height: 41px;
        background: #1f426d;
        display: flex;
        align-items: center;
        p {
          width: 16.66%;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
        }
      }
      .content-out {
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
        .table-content {
          width: 100%;
          height: 92px;
          display: flex;
          align-items: center;
          background: #0f243c;
          &:nth-of-type(odd) {
            background: #142d4c;
          }
          div {
            text-align: center;
            width: 16.66%;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            img {
              width: 97px;
              height: 70px;
              border-radius: 2px;
            }
            &.price {
              font-weight: bold;
              color: #ffb600;
            }
          }
        }
      }
    }
  }
  .market-r {
    height: 100%;
    width: 451px;
    background: #142d4c;
    padding: 92px 37px;
  }
}
</style>
